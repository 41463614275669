<template>
  <div>
    <!-- 最上端綠色橫bar -->
    <div class="top_bar" id="csId" ref="topRef">
      <div class="top_bar_container">
        <div class="top_bar_left">客服專線:(02)2311-3260</div>
        <div class="top_bar_right">
          <a href="https://www.facebook.com/myteacher12345" target="_blank">
            <img alt src="../../assets/image/fb_icon.png" />
            <span>粉絲團</span>
          </a>
          <a
            target="_blank"
            href="https://www.youtube.com/channel/UCC42p8RfYnd_sACu_KoVETQ/"
          >
            <img alt src="../../assets/image/yt_icon.png" />
            <span>影音頻道</span>
          </a>
          <a target="_blank" href="https://www.104.com.tw/company/1a2x6biida">
            <img alt src="../../assets/image/104_icon.png" />
            <span>人才招募</span>
          </a>
        </div>
      </div>
    </div>
    <!-- 最上端綠色橫bar end -->
    <!-- 選項欄 -->
    <div class="top_nav" id="topNav" :class="{ top_nav_fixed: headerStyle }">
      <div class="top_nav_container">
        <div class="top_nav_logo">
          <a @click="goRouterNew('home')">
            <img alt="好師到LOGO" src="../../assets/image/logo.jpg" />
          </a>
        </div>
        <div class="top_nav_switch" @click="openNavDialog"></div>
        <!-- <transition name="fade">
          <div v-if="show">hello world</div>
        </transition>-->
        <div class="pup_bg" v-if="isPupbg">
          <img
            alt="關閉"
            class="pup_bg_close"
            @click="closeDialog('isPupbg')"
            src="../../assets/image/close_icon.png"
          />
        </div>
        <div
          class="top_nav_btn_container"
          :class="{ top_nav_btn_open: topnavAddc, top_nav_btn_open: isPupbg }"
        >
          <a @click="goRouterNew('home')">
            <div class="top_nav_btn" :class="{ active: topNavId == 'home' }">
              首頁
            </div>
          </a>
          <a @click="goRouterNew('iTeacher')">
            <div
              class="top_nav_btn"
              :class="{ active: topNavId == 'iTeacher' }"
            >
              <div>
                <span>iTeacher</span>
                <span>一對一輔導</span>
              </div>
            </div>
          </a>
          <a @click="goRouterNew('iTutor')">
            <div class="top_nav_btn" :class="{ active: topNavId == 'iTutor' }">
              <div>
                <span>iTutor</span>
                <span>一對一家教</span>
              </div>
            </div>
          </a>
          <a class="log_in_before" @click="toLeasonse" v-if="!isStudentLogin">
            <div
              class="top_nav_btn open_try_out_tip"
              :class="{ active: topNavId == 4 }"
            >
              學習體驗
            </div>
          </a>
          <a
            class="log_in_after"
            @click="toLeasonse('check')"
            v-if="isStudentLogin"
          >
            <div class="top_nav_btn open_try_out">學習體驗</div>
          </a>
          <!--
          <a @click="goRouterNew('classInfor')">
            <div
              class="top_nav_btn"
              :class="{ active: topNavId == 'classInfor' }"
            >
              上課流程
            </div>
          </a>
          <a @click="goRouterNew('leason')">
            <div
              class="top_nav_btn"
              :class="{ active: topNavId == 'leason' }"
            >
              云端精品课程
            </div>
          </a>
          -->
          <a
            v-if="isStudentLogin"
            class="log_in_after"
            @click="goRouterNew(`question?type=user`)"
          >
            <div
              class="top_nav_btn open_try_out"
              :class="{ active: topNavId == 'question' }"
            >
              測評練功房
            </div>
          </a>
          <a class="log_in_before" @click="toLeasonse" v-else>
            <div class="top_nav_btn">測評練功房</div>
          </a>
        </div>
        <div
          class="top_nav_member log_in_after"
          v-if="isStudentLogin || isTeacherLogin"
        >
          <img alt src="../../assets/image/member_icon.jpg" />
          <a @click="goRouterNew('stu_tea')">會員中心</a>&nbsp;│&nbsp;
          <span @click="loginOut">登出</span>
        </div>
        <div class="top_nav_member log_in_before" v-else>
          <img alt src="../../assets/image/member_icon.jpg" />
          <a @click="goRouterNew('login')">登入會員</a>&nbsp;│&nbsp;
          <span class="reg_open" @click="registerStep = 1">註冊</span>
        </div>
      </div>
    </div>
    <!-- 選項欄 end -->
    <!-- 注册弹窗 -->
    <register-dialog
      :registerDialog="registerStep"
      @changeStep="setRegisterStep"
    ></register-dialog>
    <!-- 注册弹窗 end -->
    <div v-if="isExperince">
      <!-- ... 操作提示-學習體驗 ... -->
      <div class="pup_container_form try_out_tip" v-if="!isStudentLogin">
        <div class="pup_form_bg" @click="closeDialog('study')"></div>
        <div class="pup_form_container">
          <div class="pup_form_text">操作提示</div>
          <div class="pup_form_text_tip">學習體驗前請先登入或註冊會員</div>
          <div class="pup_form_btn tip_close" @click="closeDialog('study')">
            關閉提示
          </div>
        </div>
      </div>
      <!-- ... 操作提示-學習體驗end ... -->
      <!-- ... 學習體驗 ... -->
      <div class="pup_container_form try_out_form" v-else>
        <div class="pup_form_bg" @click="closeDialog('study')"></div>
        <div class="pup_form_container" style="height: 600px">
          <div class="pup_form_title">學習體驗</div>
          <div v-if="!hasTestAccess">
            <div class="pup_form_infor">步驟1-請先填寫課前資料</div>
            <div class="form_input_container">
              <div class="form_input_title">學生姓名</div>
              <div class="form_input">
                <input
                  v-model="studentInfo.student_name"
                  placeholder
                  type="text"
                />
              </div>
            </div>
            <div class="form_input_container">
              <div class="form_input_title">上課年級</div>
              <div class="form_input">
                <select v-model="studentInfo.grade" @change="changeGrade">
                  <option value>選擇年級</option>
                  <option v-for="(item, i) in gradeList" :key="i" :value="item">
                    {{ item }}
                  </option>
                </select>
              </div>
            </div>
            <div class="form_input_container">
              <div class="form_input_title">上課科目</div>
              <div class="form_input">
                <select v-model="studentInfo.subject_id">
                  <option value>選擇科目</option>
                  <option
                    v-for="(subject, i) in testSubjectList"
                    :key="i"
                    :value="subject.subject_id"
                  >
                    {{ subject.subject_name }}
                  </option>
                </select>
              </div>
            </div>

            <div class="form_input_container">
              <div class="form_input_title">上課版本</div>
              <div class="form_input">
                <select v-model="studentInfo.version_id">
                  <option value="ZZ">萬事通</option>
                  <!-- <option value>選擇上課版本</option>
                  <option
                    v-for="(clomn, i) in hsdVersionList"
                    :key="i"
                    :value="clomn.version_id"
                  >
                    {{ clomn.version_name }}
                  </option> -->
                </select>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="pup_form_infor">步驟2-請先選擇測試類型</div>
            <div class="pup_form_scroll">
              <div class="form_input_container">
                <div class="form_input_title">測試類型</div>
                <div class="form_input">
                  <select v-model="test_id">
                    <option
                      v-for="test in testTableData"
                      :key="test.id"
                      :value="test.test_paper_id"
                    >
                      {{ test.test_name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="pup_form_infor">
                步驟3-送出資料後，為了瞭解學生學習狀況，網頁將轉入測評系統，學生的測評結果將為本次上課體驗規劃所用。
              </div>
              <div class="pup_form_infor">
                步驟4-測評完成後，我們將在2個工作天內與您聯絡安排上課事宜。
              </div>
              <div class="pup_form_tip">
                <input
                  v-model="experienceCheck"
                  name
                  type="checkbox"
                  value
                />我已詳述並同意下列事項
              </div>
              <div class="pup_form_notice">
                注意事項
                <br />1.每人僅限參加一次免費體驗活動，若經本公司發現同一人申請超過一次免費體驗，本公司將取消該人員後續的活動資格。
                <br />2.本活動僅提供給首次註冊之新會員，原有的學員恕不提供體驗。
                <br />3.上課所需的電腦設備、網路等相關規格，請至學生注意事項中查閱。學員需在上課前準備好相關設備及網路，若因學生端因素導致課程中斷，恕不提供補課。
                <br />4.本活動限本人參加，不可轉讓第三人。
                <br />5.其他規定請至學生注意事項查閱。
                <br />6.本公司保留最後解釋權利。
              </div>
            </div>
          </div>
          <img
            alt
            class="close_icon"
            @click="closeDialog('study')"
            src="../../assets/image/close_icon_green.png"
          />
          <div v-if="!hasTestAccess" class="pup_form_btn" @click="sendTest">
            確認
          </div>
          <div v-else>
            <div
              v-if="experienceCheck"
              class="pup_form_btn"
              @click="sentIntoTest"
            >
              確認送出並轉入測評系統
            </div>
            <div v-else class="pup_form_btn greyBtn">
              確認送出並轉入測評系統
            </div>
          </div>
        </div>
      </div>
      <!-- ... 學習體驗end ... -->
    </div>
  </div>
</template>
<script>
import registerDialog from "../childComponent/registerDialog";
import {
  getHsdLoginOut,
  getHsdCulum,
  getProductVerssion,
  getUpdateExperienceInfo,
  getUserExperienceTest,
  getOpenExperienceTest,
  checkExperienceStatus,
} from "../../api/api";
import { mapState, mapMutations, mapActions } from "vuex";
import { Message } from "element-ui";
export default {
  props: {
    headerStyle: false,
  },
  data() {
    return {
      product: "JC",
      subjectList: [], //科目列表
      isLogin: false,
      registerStep: 0, //注册弹窗
      topnavAddc: false,
      isPupbg: false, //pup_bg
      gradeList: ["國中一年級", "國中二年級", "國中三年級"],
      studentInfo: { version_id: "ZZ" }, //学习体验
      test_id: "", //選擇測試類型
      hasTestAccess: false, //测试资格
      testTableData: [], //选课测试列表
      experienceCheck: false, //是否同意
    };
  },
  components: {
    registerDialog,
  },

  computed: {
    ...mapState([
      "hsdGradeList",
      'testSubjectList',
      "hsdVersionList",
      "isStudentLogin",
      "isTeacherLogin",
      "isExperince",
      "topNavId",
    ]),
  },
  watch: {},
  created() {
    let id = this.$route.path.substr(1);
    this.changeTopNavId(id);
    this.getSubjectData();
  },
  methods: {
    ...mapMutations([
      "changeExperienceStatus",
      "changeTopNavId",
      "changeStudentLogin",
      "changeTeacherLogin",
    ]),
    ...mapActions(["getSubjectData"]),
    //登出会员
    async loginOut() {
      try {
        const res = await getHsdLoginOut();
      } catch (err) {
      } finally {
        this.isLogin = false;
        if (this.isStudentLogin) {
          this.changeStudentLogin(false);
        }
        if (this.isTeacherLogin) {
          this.changeTeacherLogin(false);
        }
        localStorage.removeItem("token");
      }
    },
    //学习体验视图窗口显示
    toLeasonse(type) {
      if ((type = "check")) {
        this.checkTestStatus();
      }
      this.isPupbg = false;
      this.changeExperienceStatus(true);
    },
    //close dialog
    closeDialog(showId) {
      if (showId == "isPupbg") {
        this.isPupbg = false;
      }
      if (showId == "study") {
        this.changeExperienceStatus(false);
      }
      this.topnavAddc = true; //导航栏样式
    },
    //小屏打开弹窗
    openNavDialog() {
      this.isPupbg = true;
    },
    setRegisterStep(id) {
      this.registerStep = id;
    },
    //检查测试状态
    async checkTestStatus() {
      const res = await checkExperienceStatus();
      if (res && res.success) {
        console.log(res);
      }
    },
    //申请测试资格
    async sendTest() {
      this.testTableData = [];
      this.hasTestAccess = false;
      const res = await getUpdateExperienceInfo(this.studentInfo);
      if (res && res.success == 1) {
        if (res.data) {
          this.hasTestAccess = true;
          this.getTestList();
          Message.success("選課测试申請成功");
        } else {
          Message.success("選課测试申請失败");
        }
      }
    },
    //适合用户的组卷列表
    async getTestList() {
      const res = await getUserExperienceTest();
      if (res.success == 1) {
        this.testTableData = res.data;
      }
    },
    //選擇測試類型轉入測試系統
    sentIntoTest() {
      if (this.test_id) {
        this.openTest();
      } else {
        Message.error("請選擇測試類型");
      }
    },
    async openTest() {
      const res = await getOpenExperienceTest({ test_paper_id: this.test_id });
      if (res && res.success == 1) {
        localStorage.setItem("test_paper_id", res.data.test_paper_id);
        localStorage.setItem("user_test_id", res.data.user_test_id);
        let newWindow = window.open("_blank");
        newWindow.location = "/startTest/index.html";
        // location.href = "/startTest/index.html";
      }
    },
    //改变年级
    changeGrade(v) {
      console.log(v);
    },
    //新的页面
    goRouterNew(id) {
      this.isPupbg = false;
      // console.log(id);
      let router = "";
      if (id == "stu_tea") {
        if (this.isStudentLogin) {
          router = "/student";
        }
        if (this.isTeacherLogin) {
          router = "/teacher";
        }
      } else {
        this.changeTopNavId(id);
        router = "/" + id;
      }
      if (window.location.pathname == router) return;
      this.$router.push(router);
    },
  },
};
</script>
<style scoped>
@import "../../assets/css/main.css";
select {
  color: #767676 !important;
}
.greyBtn {
  background: #9c9b9b;
}
</style>